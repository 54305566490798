<template>
  <div>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入缴费名称">
            <el-input v-model="search.name" placeholder="请输入缴费名称">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入学年度">
            <el-input v-model="search.years" placeholder="请输入学年度">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请选择层次" prop="name">
            <el-select
              v-model="search.level_id"
              clearable
              placeholder="请选择层次"
            >
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入收费总额">
            <el-input
              v-model="search.total_amount"
              placeholder="请输入收费总额"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="9">
          <el-form-item label="请选择开始结束时间">
            <el-date-picker
              clearable
              v-model="time"
              type="daterange"
              range-separator="到"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>

        <el-col :span="1">
          <el-button type="primary" @click="onPaymentList"> 搜索 </el-button>
        </el-col>
        <el-col :span="1">
          <el-button
            v-permission="['financeAddFees']"
            style="margin-left: 20px"
            type="primary"
            @click="router.push('/finance/addFees/')"
          >
            添加
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 列表 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="name" label="缴费名称" />
      <el-table-column prop="level_name" label="层级" />
      <el-table-column prop="years" label="学年度" />
      <el-table-column prop="total_amount" label="收费总额" />
      <el-table-column label="缴费方式">
        <template v-slot="{ row }">
          {{ row.pay_type ? '微信支付' : '光大银行' }}
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="缴费开始时间" />
      <el-table-column prop="end_time" label="缴费结束时间" />
      <el-table-column label="操作" width="220">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onChangeTheTime(row)"
            >修改时间</el-button
          >
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >明细</el-button
          >
          <el-popconfirm
            title="是否删除该缴费?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 修改时间 -->
    <change-the-time
      v-model="timeVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></change-the-time>

    <!-- 明细 -->
    <pay-details
      v-model="detailVisible"
      :orderid="orderid"
      :startTime="startTime"
      :endTime="endTime"
    ></pay-details>
  </div>
</template>

<script setup>
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { ref, watch } from 'vue'
import { paymentListAPI, payTheFeesDeleteAPI } from '@/api/payFees'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import changeTheTime from './components/changeTheTime.vue'
import payDetails from './components/payDetails.vue'
const router = useRouter()
/** 搜索 S */
const search = ref({
  page: 1, // 当前页
  limit: 10, // 每页显示条数
  total: 0,
  name: '', // 缴费名称
  years: '', // 学年度
  level_id: '', // 层次id
  total_amount: '', // 学费金额
  start_time: '', // 开始时间
  end_time: '' // 结束时间
})
// 时间
const time = ref([])
watch(
  () => time,
  (val) => {
    console.log(val.value)
    if (!val.value) {
      search.value.start_time = ''
      search.value.end_time = ''
    } else {
      search.value.start_time = val.value[0]
      search.value.end_time = val.value[1]
    }
  },
  {
    deep: true
  }
)
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
/** 搜索 E */

/** 表格 S */
// 列表数据
const tableData = ref([])
const onPaymentList = async () => {
  const { data, total } = await paymentListAPI(search.value)
  search.value.total = total
  tableData.value = data
}
onPaymentList()

// 删除
const deleteBtn = async (id) => {
  await payTheFeesDeleteAPI({ id })
  ElMessage.error('缴费删除成功')
  onPaymentList()
}
// 明细
const detailVisible = ref(false)
const orderid = ref('')
const startTime = ref('')
const endTime = ref('')
const onEditBtn = (row) => {
  startTime.value = row.start_time
  endTime.value = row.end_time
  detailVisible.value = true
  console.log(startTime.value, endTime.value)
  orderid.value = row.id
}
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onPaymentList()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onPaymentList()
}
/**
 *  分页 E
 */

/** 修改时间 S */
const timeVisible = ref(false)
const item = ref({})
const onChangeTheTime = (row) => {
  timeVisible.value = true
  item.value = row
}
/** 修改时间 E */
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
