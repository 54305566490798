<template>
  <div>
    <el-dialog v-model="detailVisible" title="明细" width="50%">
      <div>
        <el-row>
          <el-button type="primary" plain @click="addDetail"> 新增 </el-button>
        </el-row>

        <div id="printTable">
          <el-table
            :data="tableData"
            border
            style="width: 100%; margin-top: 20px"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              prop="payment_name"
              show-overflow-tooltip
              label="收费项目"
            />
            <el-table-column prop="number" label="执行编码" />
            <el-table-column prop="expenses" label="收费金额" />
            <el-table-column label="操作" width="150">
              <template #default="{ row }">
                <el-button
                  :disabled="row.state === 1"
                  link
                  type="primary"
                  size="small"
                  @click="editDetail(row)"
                >
                  修改
                </el-button>

                <el-popconfirm
                  title="是否删除该项目?"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="deleteBtn(row.id)"
                >
                  <template #reference>
                    <el-button
                      :disabled="row.state === 1"
                      link
                      type="danger"
                      size="small"
                    >
                      删除
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <el-row style="margin-top: 20px">
          <el-col>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 50, 100, 500]"
              :page-size="search.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="search.total"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </div>

      <!-- 新增 -->
      <template>
        <el-dialog
          v-model="addVisible"
          width="30%"
          title="新增明细"
          append-to-body
        >
          <el-form
            ref="addDetailRef"
            :model="addDetailForm"
            :rules="addDetailRules"
            label-width="80px"
            status-icon
          >
            <el-form-item label="缴费 ID" prop="pay_settings_id">
              <el-input v-model="addDetailForm.pay_settings_id" />
            </el-form-item>

            <el-form-item label="缴费项目" prop="charge_items">
              <el-select
                v-model="addDetailForm.charge_items"
                placeholder="缴费项目"
                style="width: 100%"
              >
                <el-option
                  v-for="item in paymentItemArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="缴费金额" prop="expenses">
              <el-input v-model="addDetailForm.expenses" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm(addDetailRef)"
                >确认</el-button
              >
              <el-button @click="resetForm(addDetailRef)">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </template>

      <!-- 修改 -->
      <template>
        <el-dialog
          v-model="editVisible"
          width="30%"
          title="修改明细"
          append-to-body
        >
          <el-form
            ref="editDetailRef"
            :model="editDetailForm"
            :rules="editDetailRules"
            label-width="80px"
            status-icon
          >
            <el-form-item label="缴费项目" prop="id">
              <el-input v-model="editDetailForm.payment_name" disabled />
            </el-form-item>

            <el-form-item label="缴费金额" prop="expenses">
              <el-input v-model="editDetailForm.expenses" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="editSubmitForm(editDetailRef)"
                >确认</el-button
              >
              <el-button @click="editResetForm(editDetailRef)">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </template>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="btnCancel">取消</el-button>
          <el-button type="primary" @click="btnConfirm">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import {
  paysetDetailAPI,
  paysetDelAPI,
  paymentItemAPI,
  addDetailpaysetAPI,
  paydetailsetSaveAPI
} from '@/api/payFees'
import { ElMessage } from 'element-plus'
const props = defineProps({
  modelValue: {
    // 接收 v-model
    type: Boolean,
    required: true
  },

  orderid: {
    type: String,
    required: true
  },
  startTime: {
    type: String
  },
  endTime: {
    type: String
  }
})
defineEmits(['update:modelValue']) // 注册 v-model 事件
const detailVisible = useVModel(props) // 得到一个相应式的数据

/** 动态显示 S */
const type = ref(['项目名称', '执收编码', '数量', '收费金额', '发放状态'])
watch(
  () => type,
  (val) => {
    console.log(val.value)
  },
  {
    deep: true
  }
)

/** 动态显示 E */

/** 表格数据 S */
const search = ref({
  page: 1,
  limit: 5,
  total: 0,
  id: ''
})
watch(
  () => props.orderid,
  (val) => {
    search.value.id = val
    addDetailForm.value.pay_settings_id = val
    onOrderDetails()
  }
)
const tableData = ref([])
const onOrderDetails = async () => {
  const { data, total } = await paysetDetailAPI(search.value)
  search.value.total = total
  tableData.value = data
  console.log(data)
}

// 删除
const deleteBtn = async (id) => {
  try {
    await paysetDelAPI({ id })
    onOrderDetails()
    ElMessage.success('明细删除成功')
  } catch (error) {
    console.log(error)
  }
}

// 新增 S
const addVisible = ref(false)

const addDetail = () => {
  addVisible.value = true
}
// 新增明细 form
const addDetailForm = ref({
  pay_settings_id: '', // 缴费 ID
  charge_items: '', // 缴费项目
  expenses: '', // 收费金额
  number: '',
  payment_name: ''
})

// 缴费项目
const paymentItemArr = ref([])
const onPaymentItem = async () => {
  const data = await paymentItemAPI()
  paymentItemArr.value = data
}
onPaymentItem()

// 规则
const addDetailRules = ref({
  pay_settings_id: [
    { required: true, message: '缴费 ID 必须填写', trigger: 'blur' }
  ],
  charge_items: [
    { required: true, message: '缴费项目必须选择', trigger: 'blur' }
  ],
  expenses: [{ required: true, message: '缴费金额必须填写', trigger: 'blur' }]
})

const addDetailRef = ref(null)
// 确认
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      const payTheFeesOBJ = paymentItemArr.value.filter(
        (item) => item.id === addDetailForm.value.charge_items
      )
      addDetailForm.value.number = payTheFeesOBJ[0].number
      addDetailForm.value.payment_name = payTheFeesOBJ[0].name

      await addDetailpaysetAPI(addDetailForm.value)
      addVisible.value = false
      addDetailForm.value = {
        pay_settings_id: props.orderid, // 缴费 ID
        charge_items: '', // 缴费项目
        expenses: '' // 收费金额
      }
      onOrderDetails()
    } else {
      ElMessage.error('明细添加失败')
    }
  })
}
// 取消
const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  addVisible.value = false
}

// 修改 S
const editVisible = ref(false)

const editDetail = (row) => {
  if (Date.parse(props.startTime) < Date.now()) {
    return ElMessage.error('已开始或已结束的缴费不可操作')
  } else {
    editDetailForm.value.id = row.id
    editDetailForm.value.payment_name = row.payment_name

    editDetailForm.value.expenses = row.expenses
    editVisible.value = true
  }
}
// 新增明细 form
const editDetailForm = ref({
  id: '',
  payment_name: '',
  expenses: '' // 收费金额
})

// 规则
const editDetailRules = ref({
  id: [{ required: true, message: '缴费 ID 必须填写', trigger: 'blur' }],

  expenses: [{ required: true, message: '缴费金额必须填写', trigger: 'blur' }]
})

const editDetailRef = ref(null)
// 确认
const editSubmitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await paydetailsetSaveAPI(editDetailForm.value)
      editVisible.value = false
      editDetailForm.value = {
        id: '',
        expenses: '' // 收费金额
      }
      onOrderDetails()
    } else {
      ElMessage.error('明细添加失败')
    }
  })
}

// 取消
const editResetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  editVisible.value = false
}

// 修改 E

/** 表格数据 E */

/** 明细弹窗 S */
const btnCancel = () => {
  detailVisible.value = false
}
const btnConfirm = () => {
  detailVisible.value = false
}

/** 明细弹窗 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onOrderDetails()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onOrderDetails()
}

/**
 *  分页 E
 */
</script>

<style></style>
